const buttonDefaults = {
  fontSize: (t) => t.fontSizes[1],
  fontFamily: (t) => t.fonts.heading,
  border: (t) => `2px solid ${t.colors.primary}`,
  borderRadius: 4,
  display: "inline-block",
  fontFamily: "heading",
  fontWeight: "normal",
  px: 2,
  py: 1,
  cursor: "pointer",
  textDecoration: "none",
  transition: ".2s linear border, all .5s ease-in-out",
  ":focus": {
    outline: (t) => `2px solid ${t.colors.primary}`,
    outlineOffset: "2px",
  },
};

const linkDefaults = {
  color: "inherit",
  transition: "color .3s ease-in-out",
  ":hover": {
    color: "primary",
  },
  ":focus": {
    outline: (t) => `2px solid ${t.colors.primary}`,
    outlineOffset: "2px",
  },
};

const generateModularScale = (exponentList = [4, 3, 2, 1, -1], ratio = 1.5) =>
  exponentList.map((exponent) =>
    exponent === 0 ? 0 : `${Math.pow(ratio, exponent)}rem`
  );

export default {
  colors: {
    text: "#e8eafc",
    background: "#09063c",
    primary: "#00dfdd",
    secondary: "#00d197",
    accent: "#ffc1de",
    highlight: "#ffc02a",
    alert: "#F63D3D",
    muted: "#B3ACD1",
    mutedBackground: "#3D4076",
  },
  fonts: {
    body: "'Work Sans', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
    heading:
      "'Montserrat Alternates', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  space: generateModularScale([5, 4, 3, 2, 1, -1, 0]).reverse(),
  fontSizes: generateModularScale([5, 4, 3, 2, 1, -1], 1.2).reverse(),
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  breakpoints: ["576px", "768px", "992px", "1200px", "1320px"],
  sizes: {
    sm: 540,
    md: 720,
    lg: 960,
    xl: 1140,
  },
  easing: "cubic-bezier(0.645, 0.045, 0.355, 1)",
  transition: "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)",
  h1: {
    primary: {
      fontSize: [1, 2, 3],
      fontWeight: "normal",
      fontFamily: "body",
      lineHeight: "heading",
      mb: 1,
    },
    small: {
      fontSize: [0, 1, 1],
      fontWeight: "normal",
      fontFamily: "body",
      lineHeight: "heading",
      mb: 0,
    },
    large: {
      fontSize: [2, 4],
      fontWeight: "normal",
      fontFamily: "heading",
      lineHeight: "heading",
      mb: 1,
    },
  },
  h2: {
    primary: {
      fontSize: [2],
      fontFamily: "heading",
      fontWeight: "normal",
      lineHeight: "heading",
      mb: [2],
      textAlign: "center",
      width: "100%",
    },
  },
  h3: {
    primary: {
      fontSize: [2],
      fontFamily: "heading",
      fontWeight: "normal",
      lineHeight: "heading",
      mb: [2, 3],
    },
  },
  p: {
    intro: {
      fontSize: 1,
      maxWidth: "580px",
      textAlign: "center",
      width: "100%",
    },
    status: {
      fontSize: 1,
      maxWidth: "580px",
      textAlign: "center",
      width: "100%",
    },
  },
  button: {
    primary: {
      ...buttonDefaults,
      bg: "primary",
      color: "background",
      ":focus": {
        ...buttonDefaults[":focus"],
        bg: "secondary",
      },
      ":hover": {
        bg: "secondary",
      },
    },
    hollow: {
      ...buttonDefaults,
      bg: "background",
      color: "primary",
      ":focus": {
        ...buttonDefaults[":focus"],
      },
      ":hover": {
        borderColor: "accent",
      },
    },
    small: {
      ...buttonDefaults,
      bg: "background",
      color: "primary",
      padding: 1,
      borderColor: "transparent",
      ":focus": {
        ...buttonDefaults[":focus"],
      },
      ":hover": {
        borderColor: "accent",
      },
    },
  },
  link: {
    primary: {
      ...linkDefaults,
      ":focus": {
        ...linkDefaults[":focus"],
        color: "secondary",
      },
      ":hover": {
        color: "secondary",
      },
    },
  },
  text: {
    small: {
      fontSize: 0,
    },
    medium: {
      fontSize: 1,
    },
    visuallyHidden: {
      position: "absolute",
      overflow: "hidden",
      clip: "rect(0 0 0 0)",
      height: "1px",
      width: "1px",
      margin: "-1px",
      padding: "0",
      border: "0",
      display: "block",
    },
  },
  layout: {
    primary: {
      marginTop: [2, 4],
      // display: "grid",
      //gridTemplateColumns: ["5% auto 5%", "15% auto 15%"],
      //"> div": {
      //gridColumnStart: "2",
      //gridColumnEnd: "2",
      //},
    },
  },
  styles: {
    root: {
      img: {
        width: "100%",
        height: "auto",
      },
    },
  },
};
