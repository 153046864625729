module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/packages/gatsby-theme-portfolio/src/components/PageLayout.js"},"extensions":[".mdx"],"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo/sites/portfolio"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Marinda S","short_name":"MS","start_url":"/","background_color":"#09063c","theme_color":"#09063c","display":"standalone","include_favicon":false,"icons":[],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","cacheDigest":null},
    },{
      plugin: require('../../../packages/gatsby-theme-portfolio/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
